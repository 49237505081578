import React from "react";
import {
  Text,
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  useDisclosure,
  Box,
  Flex,
  Image,
} from "@chakra-ui/react";
import "../assets/css/services.css";
import { DownloadIcon } from "@chakra-ui/icons";

export default function SmallerPortCard({
  image,
  content,
  subImage,
  title,
  additionalActions,
}: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        backgroundImage={image}
        backgroundSize={"cover"}
        className="portCard"
        onClick={() => onOpen()}
      >
        {/* Portfolio Image */}
        <Flex
          backgroundColor={"#32aab5"}
          borderRadius={"50%"}
          w="50px"
          h="50px"
          justifyContent={"center"}
          alignItems="center"
          position={"relative"}
          top="180px"
          left={["120px", "130px", "180px"]}
          boxShadow={"5px 5px 5px rgba(68,68,68,0.6)"}
        >
          <DownloadIcon my="0.5rem" color={"#fff"} onClick={() => onOpen()} />
        </Flex>
      </Box>

      <Modal
        size={"6xl"}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent p="1rem" mx="1rem">
          <ModalCloseButton />
          <ModalBody my="2rem">
            <Flex
              m="0.25rem"
              w="100%"
              flexDirection={"row"}
              flexWrap="wrap"
              alignItems={"center"}
            >
              <Image
                src={subImage}
                boxSize="300px"
                objectFit={"contain"}
                borderRadius={10}
              />
              <Box pt="1rem" w={["100%", "100%", "100%", "60%"]}>
                {title}
                {content}
                {additionalActions}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
