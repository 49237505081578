import { Flex, Text } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export const MobileTestimonials = ({
  content,
  author,
  onclick,
}: {
  content: ReactNode;
  author: string;
  onclick: (params: ReactNode) => void;
}) => {
  return (
    <Flex
      maxWidth={"800px"}
      h={"200px"}
      borderRadius={20}
      my="1rem"
      flexDirection={"row"}
      borderWidth={1}
      borderBottom={1}
      mx="2rem"
    >
      <Flex flexDirection={"column"} p="30px" w="100%">
        {content}
        <Text
          textAlign={"right"}
          fontSize={"1rem"}
          fontFamily="Open Sans"
          fontWeight={"500"}
          color="#919e9c"
          textStyle={"italicize"}
          alignSelf="flex-end"
          pt="1rem"
        >
          {author}
        </Text>
      </Flex>
      <Flex
        borderTopRightRadius={10}
        borderBottomRightRadius={10}
        backgroundColor={"#32aab5"}
        boxShadow={"inset 5px 0px 5px rgba(68,68,68,0.6)"}
        w={"3rem"}
        justifyContent="center"
        alignItems={"center"}
        cursor="pointer"
        onClick={onclick}
      >
        <InfoOutlineIcon color={"#fff"} />
        {/* <Text fontSize={"0.75rem"} fontFamily="Open Sans" color="#fff">

        </Text> */}
      </Flex>
    </Flex>
  );
};
