import React, { ReactNode } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export const SmallerTestimonials = ({
  content,
  author,
  onclick,
}: {
  content: ReactNode;
  author: string;
  onclick: (params: ReactNode) => void;
}) => {
  return (
    <Flex
      maxWidth={"800px"}
      borderRadius={20}
      my="1rem"
      flexDirection={"row"}
      borderWidth={1}
      borderTop={1}
      mx="2rem"
    >
      <Flex flexDirection={"column"} p="30px" w="100%">
        <InfoOutlineIcon
          my="0.5rem"
          alignSelf={"flex-end"}
          color={"#32aab5"}
          onClick={onclick}
        />
        {content}
        <Text
          textAlign={"right"}
          fontSize={"1rem"}
          fontFamily="Open Sans"
          fontWeight={"500"}
          color="#919e9c"
          textStyle={"italicize"}
          alignSelf="flex-end"
          pt="1rem"
        >
          {author}
        </Text>
      </Flex>
    </Flex>
  );
};
