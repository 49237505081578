import React from "react";
import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  useDisclosure,
  Box,
  Flex,
  Image,
} from "@chakra-ui/react";
import "../assets/css/services.css";

export default function PortCard({
  image,
  content,
  subImage,
  title,
  additionalActions,
}: any) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box
        backgroundImage={image}
        backgroundSize={"cover"}
        className="portCard"
        onClick={() => onOpen()}
      >
        {/* Portfolio Image */}
      </Box>

      <Modal
        size={"6xl"}
        scrollBehavior={"inside"}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <ModalOverlay />
        <ModalContent p="1rem" mx="2rem">
          <ModalCloseButton />
          <ModalBody my="2rem">
            <Flex
              m="0.25rem"
              w="100%"
              flexDirection={"row"}
              flexWrap="wrap"
              alignItems={"center"}
            >
              <Image
                src={subImage}
                boxSize="400px"
                objectFit={"contain"}
                borderRadius={10}
              />
              <Box px="2.5rem" w={["100%", "100%", "100%", "60%"]}>
                {title}
                {content}
                {additionalActions}
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
