import React from "react";

export default function Screen() {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 256 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 174.429V31.5714C3 23.9938 5.92658 16.7266 11.1359 11.3684C16.3453 6.01019 23.4107 3 30.7778 3H225.222C232.589 3 239.655 6.01019 244.864 11.3684C250.073 16.7266 253 23.9938 253 31.5714V174.429C253 182.006 250.073 189.273 244.864 194.632C239.655 199.99 232.589 203 225.222 203H30.7778C23.4107 203 16.3453 199.99 11.1359 194.632C5.92658 189.273 3 182.006 3 174.429Z"
        stroke="#32AAB5"
        stroke-width="5"
      />
      <path
        d="M44.668 45.8572H58.5569M123.376 60.1429L100.224 103H155.779L132.626 145.857L123.376 60.1429Z"
        stroke="#32AAB5"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
