import React from "react";

export default function Chip() {
  return (
    <svg
      width="130"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.5 137.5H137.5V62.5H62.5V137.5ZM175 68.75H193.75C197.188 68.75 200 65.9375 200 62.5C200 59.0625 197.202 56.25 193.75 56.25H175V50C175 36.1914 163.809 25 150 25H143.75V6.25C143.75 2.79844 140.938 0 137.5 0C134.048 0 131.25 2.79844 131.25 6.25V25H106.25V6.25C106.25 2.79844 103.438 0 100 0C96.5625 0 93.75 2.79844 93.75 6.25V25H68.75V6.25C68.75 2.79844 65.9375 0 62.5 0C59.0625 0 56.25 2.79844 56.25 6.25V25H50C36.1914 25 25 36.1914 25 50V56.25H6.25C2.79844 56.25 0 59.0625 0 62.5C0 65.9375 2.79844 68.75 6.25 68.75H25V93.75H6.25C2.79844 93.75 0 96.5625 0 100C0 103.438 2.79844 106.25 6.25 106.25H25V131.25H6.25C2.79844 131.25 0 134.062 0 137.5C0 140.938 2.79844 143.75 6.25 143.75H25V150C25 163.809 36.1914 175 50 175H56.25V193.75C56.25 197.188 59.0625 200 62.5 200C65.9523 200 68.75 197.202 68.75 193.75V175H93.75V193.75C93.75 197.202 96.5484 200 100 200C103.452 200 106.25 197.202 106.25 193.75V175H131.25V193.75C131.25 197.202 134.048 200 137.5 200C140.952 200 143.75 197.202 143.75 193.75V175H150C163.809 175 175 163.809 175 150V143.75H193.75C197.202 143.75 200 140.952 200 137.5C200 134.048 197.202 131.25 193.75 131.25H175V106.25H193.75C197.188 106.25 200 103.438 200 100C200 96.5625 197.202 93.75 193.75 93.75H175V68.75ZM150 143.75C150 147.202 147.202 150 143.75 150H56.25C52.8125 150 50 147.188 50 143.75V56.25C50 52.8125 52.8125 50 56.25 50H143.75C147.188 50 150 52.8125 150 56.25V143.75Z"
        fill="#32AAB5"
      />
    </svg>
  );
}
