import React from "react";

export default function Station() {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M135 168.421V189.474H117.3C113.9 195.789 107.4 200 100 200C92.6 200 86.1 195.789 82.7 189.474H65V168.421H82.7C84.4 165.263 87 162.526 90 160.737V147.368H80C74.5 147.368 70 142.632 70 136.842V105.263H40V147.368C40 153.158 35.5 157.895 30 157.895H10C4.5 157.895 0 153.158 0 147.368V52.6316C0 46.8421 4.5 42.1053 10 42.1053H30C35.5 42.1053 40 46.8421 40 52.6316V84.2105H70V52.6316C70 46.8421 74.5 42.1053 80 42.1053H90V31.5789H80C74.5 31.5789 70 26.8421 70 21.0526V10.5263C70 4.73684 74.5 0 80 0H120C125.5 0 130 4.73684 130 10.5263V21.0526C130 26.8421 125.5 31.5789 120 31.5789H110V42.1053H120C125.5 42.1053 130 46.8421 130 52.6316V84.2105H160V52.6316C160 46.8421 164.5 42.1053 170 42.1053H190C195.5 42.1053 200 46.8421 200 52.6316V147.368C200 153.158 195.5 157.895 190 157.895H170C164.5 157.895 160 153.158 160 147.368V105.263H130V136.842C130 142.632 125.5 147.368 120 147.368H110V160.737C113 162.526 115.6 165.263 117.3 168.421H135ZM10 136.842V147.368H30V136.842H10ZM10 115.789V126.316H30V115.789H10ZM10 94.7368V105.263H30V94.7368H10ZM10 73.6842V84.2105H30V73.6842H10ZM10 52.6316V63.1579H30V52.6316H10ZM170 136.842V147.368H190V136.842H170ZM170 115.789V126.316H190V115.789H170ZM170 94.7368V105.263H190V94.7368H170ZM170 73.6842V84.2105H190V73.6842H170ZM170 52.6316V63.1579H190V52.6316H170Z"
        fill="#32AAB5"
      />
    </svg>
  );
}
