import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Text,
  Heading,
  Image,
  UnorderedList,
  ListItem,
  Grid,
  GridItem,
  List,
} from "@chakra-ui/react";

import Main from "../components/Main";
import Nav from "../components/Nav";
import Seo from "../components/Seo";
import SmallerPortCard from "../components/SmallerPortCard";

import servicesTags from "./servicesTags.json";
import Iphone12 from "../components/Graphics/Iphone12";

import "../assets/css/services.css";
import Footer from "../components/Footer";
// @ts-ignore
import Lotus from "../images/services/lotusBG.svg";
// @ts-ignore
import Circbg from "../images/services/circ_line_bg.svg";
import ClearCard from "../components/ClearCard";
import Chip from "../components/Graphics/Chip";
import Station from "../components/Graphics/Station";
import Screen from "../components/Graphics/Screen";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import PortCard from "../components/PortCard";
// @ts-ignore
import giga_logo from "../images/portfolio/cignal-play/giga_logo.jpg";
// @ts-ignore
import cup_icon from "../images/portfolio/cup/sc1.png";
// @ts-ignore
import da_icon from "../images/portfolio/doc-ayn/sc1.jpeg";
// @ts-ignore
import rc_logo from "../images/portfolio/myRcf/sc.png";
// @ts-ignore
import ta_logo from "../images/portfolio/transitions/bb.png";
// @ts-ignore
import whs_logo from "../images/portfolio/whsMonitor/bb.png";
// @ts-ignore
import giga_sub from "../images/portfolio/cignal-play/bb.png";
// @ts-ignore
import cup_sub from "../images/portfolio/cup/cup_icon.png";
// @ts-ignore
import doc_sub from "../images/portfolio/doc-ayn/da_icon.png";
// @ts-ignore
import rc_sub from "../images/portfolio/myRcf/rc_logo.png";
// @ts-ignore
import ta_sub from "../images/portfolio/transitions/ta_logo.png";
// @ts-ignore
import whs_sub from "../images/portfolio/whsMonitor/whs_logo.png";
// @ts-ignore
import atlas_icon from "../images/portfolio/atlas/atlas_icon.png";
// @ts-ignore
import atlas_sub from "../images/portfolio/atlas/atlas_sc.png";
// @ts-ignore
import orb_icon from "../images/portfolio/orb/orb_icon.png";
// @ts-ignore
import orb_sub from "../images/portfolio/orb/sc_o.png";
// @ts-ignore
import gplay from "../images/portfolio/gplay.png";
// @ts-ignore
import appstore from "../images/portfolio/appstore.png";
import { MobileTestimonials } from "../components/MobileTestimonials";
import { SmallerTestimonials } from "../components/SmallerTestimonials";

gsap.registerPlugin(ScrollTrigger);
export default function Services() {
  const [open, setOpen] = useState(false);
  const [activeContent, setActiveContent] = useState<React.ReactNode>();

  useEffect(() => {
    // gsap timeline animations

    const tl = gsap.timeline();
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".lotus",
        scrub: 1,
        start: "60% center",
      },
    });
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".lotus",
        start: "center top",
      },
    });

    tl.fromTo(".services", { opacity: 0 }, { opacity: 1, duration: 1 })
      .fromTo(".subHeading", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".lotus", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      .fromTo(".lotusIphone", { opacity: 0 }, { opacity: 1, duration: 1 });

    tl2
      .to(
        ".services",
        {
          y: -50,
          duration: 0.5,
          ease: "ease",
        },
        "<"
      )
      .to(
        ".subHeading",
        {
          y: -50,
          duration: 0.25,
          ease: "ease",
        },
        "<"
      )
      .to(
        ".lotus",
        {
          y: -20,
          duration: 0.25,
          ease: "ease",
        },
        "<"
      )
      .to(
        ".lotusIphone",
        {
          y: -20,
          duration: 1,
          ease: "ease",
        },
        "<"
      );

    tl3
      .fromTo(".proud", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".proudHeading", { opacity: 0 }, { duration: 1, opacity: 1 })
      .fromTo(".ph1", { opacity: 0 }, { duration: 0.25, opacity: 1 })
      .fromTo(".ph2", { opacity: 0 }, { duration: 0.25, opacity: 1 })
      .fromTo(".ph3", { opacity: 0 }, { duration: 0.25, opacity: 1 })
      .fromTo(".ph4", { opacity: 0 }, { duration: 0.25, opacity: 1 })
      .fromTo(".ph5", { opacity: 0 }, { duration: 0.5, opacity: 1 });

    const service = gsap.timeline({
      scrollTrigger: {
        trigger: ".serviceSection",
        start: "top center",
      },
    });

    service.fromTo(
      ".serviceSection",
      { opacity: 0 },
      { opacity: 1, duration: 1 }
    );

    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: ".testimonialsGrid",
        start: "top center",
      },
    });

    tl4
      .fromTo(".grid1", { opacity: 0 }, { duration: 0.25, opacity: 1 })
      .fromTo(".grid2", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".grid3", { opacity: 0 }, { duration: 0.5, opacity: 1 })
      .fromTo(".grid4", { opacity: 0 }, { duration: 0.5, opacity: 1 });

    return () => {
      tl.kill();
    };
  }, []);

  const handleTestimonialModal = (content: React.ReactNode) => {
    setOpen((prev) => !prev);
    setActiveContent(content);
  };

  return (
    <>
      <Seo
        title={servicesTags.title}
        description={servicesTags.description}
        url={servicesTags.url}
      />
      <Main>
        <Nav active="services" />
        <Flex
          w="100%"
          justifyContent="space-evenly"
          alignItems="center"
          wrap="wrap-reverse"
          h={["130vh", "140vh", "120vh", "90vh", "85vh"]}
          backgroundRepeat={"no-repeat"}
          backgroundSize={"cover"}
          backgroundPosition={[
            "-30rem, 20rem",
            "-30rem, 20rem",
            "-30rem",
            "-15rem",
          ]}
          px="2rem"
          className="lotus"
        >
          <Box>
          </Box>
          <Flex
            justifySelf={"start"}
            flexDir="column"
            maxW="600px"
            w={["100%", "100%", "40%"]}
          >
            <Heading
              color="#06667C"
              fontSize="3rem"
              fontFamily="Montserrat"
              className="services"
            >
              What We Offer
            </Heading>
            <Text
              className="subHeading"
              fontFamily="Open Sans"
              fontSize="1.25rem"
              color={"#FFFFFF"}
            >
              Our team can work with you to bring your digital ideas to life,
              utilizing extensive requirements gathering, expert technical
              consultation and meaningful collaboration.
              <br />
              <br />
              To date, our team has successfully delivered nearly 300 digital
              products, catering to a diverse clientele including start-ups,
              SMEs, and renowned global brands.
            </Text>
          </Flex>
        </Flex>
        <Flex
          backgroundImage={Circbg}
          backgroundPosition={"center"}
          backgroundSize={["cover", "cover", "cover", "contain"]}
          backgroundRepeat={"no-repeat"}
          maxW="100%"
          h="auto"
          overflowX="hidden"
          alignItems={"center"}
          justifyContent="center"
          className="proud"
          w="100%"
          flexWrap={"wrap"}
          flexDirection="column"
        >
          <Flex alignItems={"center"} flexDirection="row" flexWrap={"wrap"}>
            <Text
              fontFamily={"Open Sans"}
              fontSize={["1.5rem", "1.5rem", "1.5rem", "1.75rem", "1.75rem"]}
              color="#FFFFFF"
              px={["2rem", "2rem", "2rem", "5rem", "5rem"]}
              my="1rem"
              fontWeight="600"
              textShadow={`2px 4px 3px rgba(218,218,218,0.3)`}
              className="proudHeading"
            >
              Our work employs a process
              <br />
              based on four key principles
            </Text>
            <List>
              <ListItem
                fontFamily={"Open Sans"}
                fontSize={["1rem", "1.15rem", "1.25rem", "1.5rem", "1.5rem"]}
                color="#06667c"
                px={["2rem", "2rem", "2rem", "5rem", "5rem"]}
                my="1rem"
                fontWeight={"600"}
                textShadow={`2px 4px 3px rgba(218,218,218,0.3)`}
                className="ph1"
                textAlign={"justify"}
              >
                Determine the essence of the product;
              </ListItem>
              <ListItem
                fontFamily={"Open Sans"}
                fontSize={["1rem", "1.15rem", "1.25rem", "1.5rem", "1.5rem"]}
                color="#06667c"
                px={["2rem", "2rem", "2rem", "5rem", "5rem"]}
                my="1rem"
                fontWeight={"600"}
                textShadow={`2px 4px 3px rgba(218,218,218,0.3)`}
                className="ph2"
                textAlign={"justify"}
              >
                Make it relatable to users and stakeholders;
              </ListItem>
              <ListItem
                fontFamily={"Open Sans"}
                fontSize={["1rem", "1.15rem", "1.25rem", "1.5rem", "1.5rem"]}
                color="#06667c"
                px={["2rem", "2rem", "2rem", "5rem", "5rem"]}
                my="1rem"
                fontWeight={"600"}
                textShadow={`2px 4px 3px rgba(218,218,218,0.3)`}
                className="ph3"
                textAlign={"justify"}
              >
                Provide a system for sustainable development;
              </ListItem>
              <ListItem
                fontFamily={"Open Sans"}
                fontSize={["1rem", "1.15rem", "1.25rem", "1.5rem", "1.5rem"]}
                color="#06667c"
                px={["2rem", "2rem", "2rem", "5rem", "5rem"]}
                my="1rem"
                fontWeight={"600"}
                textShadow={`2px 4px 3px rgba(218,218,218,0.3)`}
                className="ph4"
                textAlign={"justify"}
              >
                Support and maintenance;
              </ListItem>
            </List>
          </Flex>
          <Box
            w={["100%", "100%", "100%", "100%", "100%", "90%"]}
            mt="5rem"
            className="ph5"
          >
            <Text
              fontFamily={"Open Sans"}
              fontSize={"1.25rem"}
              color="#FFFFFF"
              px={["2rem", "2rem", "2rem", "5rem", "5rem"]}
              my="1rem"
              textShadow={`2px 4px 3px rgba(218,218,218,0.3)`}
              textAlign={"justify"}
            >
              This process has enabled us to build long-term relationships with
              various organisations from different countries and to gain
              extensive experience in implementing digital solutions for various
              industries.
              <br />
              <br />
              The depth and diversity of projects we have worked on encompass
              the industries of Fintech, Television and Entertainment,
              Telecommunications, Agriculture, Science and Engineering, Retail
              and Consumer Goods, E-commerce, Pharmaceuticals, Transportation
              and many others.
            </Text>
          </Box>
        </Flex>
        <Flex
          // backgroundColor={"#32AAB5"}
          flexDirection="column"
          alignItems={"center"}
          justifyContent="center"
          // boxShadow="inset 0 0 10px #000000"
          my="5rem"
          className="serviceSection"
          px="2rem"
        >
          <Heading
            textAlign="center"
            fontFamily="Montserrat"
            fontSize={["2rem", "2.5rem", "3rem", "3rem", "3rem"]}
            color="#FFFFFF"
            py="2rem"
          >
            SERVICES
          </Heading>
          {/* <Carousel /> */}
          <Flex
            w="100%"
            justifyContent={"space-evenly"}
            alignItems="center"
            flexWrap={"wrap"}
          >
            <ClearCard
              icon={<Chip />}
              content={
                <Flex
                  flexDirection={"column"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Text
                    fontSize={"2rem"}
                    fontFamily="Open Sans"
                    color={"#FFFFFF"}
                    fontWeight="600"
                    my="1rem"
                  >
                    Development
                  </Text>
                  <UnorderedList styleType={"none"} textAlign="center">
                    <ListItem style={style.listStyle}>iOS and Android</ListItem>
                    <ListItem style={style.listStyle}>
                      Front-end &amp; Back-end Web
                    </ListItem>
                    <ListItem style={style.listStyle}>
                      Wearables and Smart TV
                    </ListItem>
                  </UnorderedList>
                </Flex>
              }
            />
            <ClearCard
              icon={<Screen />}
              content={
                <Flex
                  flexDirection={"column"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Text
                    fontSize={"2rem"}
                    fontFamily="Open Sans"
                    color={"#FFFFFF"}
                    fontWeight="600"
                    my="1rem"
                  >
                    Design
                  </Text>
                  <UnorderedList styleType={"none"} textAlign="center">
                    <ListItem style={style.listStyle}>Wireframes</ListItem>
                    <ListItem style={style.listStyle}>Mockups</ListItem>
                    <ListItem style={style.listStyle}>Prototypes</ListItem>
                  </UnorderedList>
                </Flex>
              }
            />
            <ClearCard
              icon={<Station />}
              content={
                <Flex
                  flexDirection={"column"}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Text
                    fontSize={"2rem"}
                    fontFamily="Open Sans"
                    color={"#FFFFFF"}
                    fontWeight="600"
                    my="1rem"
                  >
                    Testing
                  </Text>
                  <UnorderedList styleType={"none"} textAlign="center">
                    <ListItem style={style.listStyle}>
                      Functional Tests
                    </ListItem>
                    <ListItem style={style.listStyle}>
                      End-to-end Tests
                    </ListItem>
                    <ListItem style={style.listStyle}>
                      Acceptance Tests
                    </ListItem>
                  </UnorderedList>
                </Flex>
              }
            />
          </Flex>
        </Flex>
        <Heading
          textAlign="center"
          fontFamily="Montserrat"
          fontSize={["2rem", "2rem", "2.5rem", "3rem", "3rem"]}
          color="#FFFFFF"
          px="2rem"
          py="3rem"
        >
          COMPANIES WE'VE WORKED WITH
        </Heading>
        <Grid
          className="testimonialsGrid"
          h="500px"
          templateRows="repeat(7, 1fr)"
          templateColumns="repeat(5, 1fr)"
          gap={4}
          px="2rem"
          display={["none", "none", "none", "grid", "grid"]}
        >
          <GridItem
            className="grid1"
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            rowSpan={5}
            colSpan={3}
            transition="1s"
            _hover={{
              transition: "0.5s",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    We contracted Rogomi to create for us an App that will help
                    the farmers increase their yield from their farms. The app
                    had to be easy to use, available also for off-line use and
                    light enough to be installed on a wide range of mobile
                    devices. They were quick to respond to our request and
                    systematically brought out the app that suited our
                    requirements.
                    <br />
                    <br />
                    Even though they had to learn from scratch the complex
                    science of Agriculture necessary to provide recommendations
                    from the process of planting to harvesting, their team’s
                    professionalism and expertise on mobile development have
                    certainly satisfied our expectations. We would definitely
                    consider them for our other requirements in the future and
                    recommend them to our contacts.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Kenneth Logarta, Atlas Fertilizer Corporation
                  </Text>
                </Box>
              );
            }}
          >
            <Flex
              p="2rem"
              flexDirection={"column"}
              alignItems="center"
              justifyContent={"center"}
            >
              <Text
                pt="3rem"
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                So with Rogomi working on our apps, I feel that we're getting a
                developer that is easy to communicate with, executes the app
                very well, and most importantly understands the purpose of the
                app and the needs of the users.
              </Text>
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
                alignSelf="flex-end"
                pt="1rem"
              >
                - Kenneth Logarta, Atlas Fertilizer Corporation
              </Text>
            </Flex>
          </GridItem>
          <GridItem
            className="grid2"
            rowSpan={4}
            colSpan={2}
            transition={"1s"}
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            _hover={{
              transition: "0.5s",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Rogomi was recommended by a friend when I explained that
                    other app providers I'd approached didn't truly understand
                    the users' point of view for a housekeeping payroll app.
                    Finding a capable, responsive, well-priced, developer is
                    challenging, but not impossible. Essentially, though apps
                    are computer based, the starting point and the end point are
                    both human.
                    <br />
                    <br />
                    The app can be a work of art internally, but if the human
                    interface is not executed well, then the internal art goes
                    to waste. So with Rogomi working on our apps, I feel that
                    we're getting a developer that is easy to communicate with,
                    executes the app very well, and most importantly understands
                    the purpose of the app and the needs of the users.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Andrea Lanuza, Abueli Home Concierge
                  </Text>
                </Box>
              );
            }}
          >
            <Flex p="2rem" flexDirection={"column"}>
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                Their team’s professionalism and expertise on mobile development
                have certainly satisfied our expectations.
              </Text>
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
                alignSelf="flex-end"
                pt="3rem"
              >
                - Andrea Lanuza, Abueli Home Concierge
              </Text>
            </Flex>
          </GridItem>
          <GridItem
            className="grid3"
            rowSpan={4}
            colSpan={2}
            transition={"1s"}
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            _hover={{
              transition: "0.5s",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Koneqted is an independent and experimental mobile app which
                    I wanted to develop sometime 2017/2018. I did a due
                    diligence search of highly regarded app developers with
                    specific set of skills and those with track record of
                    launching apps which I thought contained highly creative
                    features.
                    <br />
                    <br />
                    Rogomi was the perfect partner for my app. The project team
                    was accommodating all throughout the process. They not only
                    worked on my ideas, but they suggested options which made
                    the app efficient and appealing to its target audience.
                    <br />
                    <br />
                    After koneqted, i collaborated with Rogomi on 3 other
                    projects - - - 2 already launched and 1 ongoing. I am
                    continually impressed with their work output and will
                    continue to affiliate with them moving forward.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Bernard Gorospe, Koneqted
                  </Text>
                </Box>
              );
            }}
          >
            <Flex p="2rem" flexDirection={"column"}>
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                Rogomi was the perfect partner for my app. The project team was
                accommodating all throughout the process.
              </Text>
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
                alignSelf="flex-end"
                pt="2rem"
              >
                - Bernard Gorospe, Koneqted
              </Text>
            </Flex>
          </GridItem>
          <GridItem
            className="grid4"
            rowSpan={3}
            colSpan={3}
            transition={"1s"}
            boxShadow={
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
            }
            _hover={{
              transition: "0.5s",
              boxShadow:
                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Two years ago, I decided to place a bid out for developers
                    for an idea for an app. I'm a single mother, based in the
                    US, who does accounting for a living and knows nothing about
                    developing an app. Of all the companies I interviewed, Ramon
                    was very quick to respond to my questions, follow up, and
                    provide a competitive price point for my project. His
                    professionalism led me to choose Rogomi.
                    <br />
                    <br />
                    During the development process, Ramon and his team always
                    kept me informed and assisted with areas that were beyond
                    knowledge. The dedication he and the Rogomi team
                    demonstrated was key in turning my app concept into a
                    reality.
                    <br />
                    <br />I would 100% recommend Rogomi to anyone looking for a
                    quality solution at a competitive price.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Anne Holton, Holton Innovations
                  </Text>
                </Box>
              );
            }}
          >
            <Flex p="2rem" flexDirection={"column"}>
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                I would 100% recommend Rogomi to anyone looking for a quality
                solution at a competitive price.
              </Text>
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
                alignSelf="flex-end"
                pt="1rem"
              >
                - Anne Holton, Holton Innovations
              </Text>
            </Flex>
          </GridItem>
        </Grid>
        <Flex
          display={["none", "none", "flex", "none", "none"]}
          alignItems="center"
          flexDirection="column"
        >
          <MobileTestimonials
            content={
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                So with Rogomi working on our apps, I feel that we're getting a
                developer that is easy to communicate with, executes the app
                very well, and most importantly understands the purpose of the
                app and the needs of the users.
              </Text>
            }
            author={"- Kenneth Logarta, Atlas Fertilizer Corporation"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    We contracted Rogomi to create for us an App that will help
                    the farmers increase their yield from their farms. The app
                    had to be easy to use, available also for off-line use and
                    light enough to be installed on a wide range of mobile
                    devices. They were quick to respond to our request and
                    systematically brought out the app that suited our
                    requirements.
                    <br />
                    <br />
                    Even though they had to learn from scratch the complex
                    science of Agriculture necessary to provide recommendations
                    from the process of planting to harvesting, their team’s
                    professionalism and expertise on mobile development have
                    certainly satisfied our expectations. We would definitely
                    consider them for our other requirements in the future and
                    recommend them to our contacts.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Kenneth Logarta, Atlas Fertilizer Corporation
                  </Text>
                </Box>
              );
            }}
          />
          <MobileTestimonials
            content={
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                Rogomi was the perfect partner for my app. The project team was
                accommodating all throughout the process.
              </Text>
            }
            author={"- Bernard Gorospe, Koneqted"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Koneqted is an independent and experimental mobile app which
                    I wanted to develop sometime 2017/2018. I did a due
                    diligence search of highly regarded app developers with
                    specific set of skills and those with track record of
                    launching apps which I thought contained highly creative
                    features.
                    <br />
                    <br />
                    Rogomi was the perfect partner for my app. The project team
                    was accommodating all throughout the process. They not only
                    worked on my ideas, but they suggested options which made
                    the app efficient and appealing to its target audience.
                    <br />
                    <br />
                    After koneqted, i collaborated with Rogomi on 3 other
                    projects - - - 2 already launched and 1 ongoing. I am
                    continually impressed with their work output and will
                    continue to affiliate with them moving forward.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Bernard Gorospe, Koneqted
                  </Text>
                </Box>
              );
            }}
          />
          <MobileTestimonials
            content={
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                Their team’s professionalism and expertise on mobile development
                have certainly satisfied our expectations.
              </Text>
            }
            author={"- Andrea Lanuza, Abueli Home Concierge"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Rogomi was recommended by a friend when I explained that
                    other app providers I'd approached didn't truly understand
                    the users' point of view for a housekeeping payroll app.
                    Finding a capable, responsive, well-priced, developer is
                    challenging, but not impossible. Essentially, though apps
                    are computer based, the starting point and the end point are
                    both human.
                    <br />
                    <br />
                    The app can be a work of art internally, but if the human
                    interface is not executed well, then the internal art goes
                    to waste. So with Rogomi working on our apps, I feel that
                    we're getting a developer that is easy to communicate with,
                    executes the app very well, and most importantly understands
                    the purpose of the app and the needs of the users.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Andrea Lanuza, Abueli Home Concierge
                  </Text>
                </Box>
              );
            }}
          />
          <MobileTestimonials
            content={
              <Text
                fontSize={"1.25rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                I would 100% recommend Rogomi to anyone looking for a quality
                solution at a competitive price.
              </Text>
            }
            author={"- Anne Holton, Holton Innovations"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Two years ago, I decided to place a bid out for developers
                    for an idea for an app. I'm a single mother, based in the
                    US, who does accounting for a living and knows nothing about
                    developing an app. Of all the companies I interviewed, Ramon
                    was very quick to respond to my questions, follow up, and
                    provide a competitive price point for my project. His
                    professionalism led me to choose Rogomi.
                    <br />
                    <br />
                    During the development process, Ramon and his team always
                    kept me informed and assisted with areas that were beyond
                    knowledge. The dedication he and the Rogomi team
                    demonstrated was key in turning my app concept into a
                    reality.
                    <br />
                    <br />I would 100% recommend Rogomi to anyone looking for a
                    quality solution at a competitive price.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Anne Holton, Holton Innovations
                  </Text>
                </Box>
              );
            }}
          />
        </Flex>
        <Flex
          display={["flex", "flex", "none", "none", "none"]}
          flexDirection="column"
        >
          <SmallerTestimonials
            content={
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                So with Rogomi working on our apps, I feel that we're getting a
                developer that is easy to communicate with, executes the app
                very well, and most importantly understands the purpose of the
                app and the needs of the users.
              </Text>
            }
            author={"- Kenneth Logarta, Atlas Fertilizer Corporation"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    We contracted Rogomi to create for us an App that will help
                    the farmers increase their yield from their farms. The app
                    had to be easy to use, available also for off-line use and
                    light enough to be installed on a wide range of mobile
                    devices. They were quick to respond to our request and
                    systematically brought out the app that suited our
                    requirements.
                    <br />
                    <br />
                    Even though they had to learn from scratch the complex
                    science of Agriculture necessary to provide recommendations
                    from the process of planting to harvesting, their team’s
                    professionalism and expertise on mobile development have
                    certainly satisfied our expectations. We would definitely
                    consider them for our other requirements in the future and
                    recommend them to our contacts.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Kenneth Logarta, Atlas Fertilizer Corporation
                  </Text>
                </Box>
              );
            }}
          />
          <SmallerTestimonials
            content={
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                Rogomi was the perfect partner for my app. The project team was
                accommodating all throughout the process.
              </Text>
            }
            author={"- Bernard Gorospe, Koneqted"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Koneqted is an independent and experimental mobile app which
                    I wanted to develop sometime 2017/2018. I did a due
                    diligence search of highly regarded app developers with
                    specific set of skills and those with track record of
                    launching apps which I thought contained highly creative
                    features.
                    <br />
                    <br />
                    Rogomi was the perfect partner for my app. The project team
                    was accommodating all throughout the process. They not only
                    worked on my ideas, but they suggested options which made
                    the app efficient and appealing to its target audience.
                    <br />
                    <br />
                    After koneqted, i collaborated with Rogomi on 3 other
                    projects - - - 2 already launched and 1 ongoing. I am
                    continually impressed with their work output and will
                    continue to affiliate with them moving forward.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Bernard Gorospe, Koneqted
                  </Text>
                </Box>
              );
            }}
          />
          <SmallerTestimonials
            content={
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                Their team’s professionalism and expertise on mobile development
                have certainly satisfied our expectations.
              </Text>
            }
            author={"- Andrea Lanuza, Abueli Home Concierge"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Rogomi was recommended by a friend when I explained that
                    other app providers I'd approached didn't truly understand
                    the users' point of view for a housekeeping payroll app.
                    Finding a capable, responsive, well-priced, developer is
                    challenging, but not impossible. Essentially, though apps
                    are computer based, the starting point and the end point are
                    both human.
                    <br />
                    <br />
                    The app can be a work of art internally, but if the human
                    interface is not executed well, then the internal art goes
                    to waste. So with Rogomi working on our apps, I feel that
                    we're getting a developer that is easy to communicate with,
                    executes the app very well, and most importantly understands
                    the purpose of the app and the needs of the users.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Andrea Lanuza, Abueli Home Concierge
                  </Text>
                </Box>
              );
            }}
          />
          <SmallerTestimonials
            content={
              <Text
                fontSize={"1rem"}
                fontFamily="Open Sans"
                fontWeight={"500"}
                color="#FFFFFF"
                textStyle={"italicize"}
              >
                I would 100% recommend Rogomi to anyone looking for a quality
                solution at a competitive price.
              </Text>
            }
            author={"- Anne Holton, Holton Innovations"}
            onclick={() => {
              handleTestimonialModal(
                <Box>
                  <Text style={style.modalTextStyle}>
                    Two years ago, I decided to place a bid out for developers
                    for an idea for an app. I'm a single mother, based in the
                    US, who does accounting for a living and knows nothing about
                    developing an app. Of all the companies I interviewed, Ramon
                    was very quick to respond to my questions, follow up, and
                    provide a competitive price point for my project. His
                    professionalism led me to choose Rogomi.
                    <br />
                    <br />
                    During the development process, Ramon and his team always
                    kept me informed and assisted with areas that were beyond
                    knowledge. The dedication he and the Rogomi team
                    demonstrated was key in turning my app concept into a
                    reality.
                    <br />
                    <br />I would 100% recommend Rogomi to anyone looking for a
                    quality solution at a competitive price.
                  </Text>
                  <Text style={style.modalNameStyle}>
                    - Anne Holton, Holton Innovations
                  </Text>
                </Box>
              );
            }}
          />
        </Flex>
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          px="1rem"
          my="5rem"
        >
          <Heading
            textAlign="center"
            fontFamily={"Montserrat"}
            fontSize={["2rem", "2rem", "2.5rem", "3rem", "3rem"]}
            color="#FFFFFF"
            px="2rem"
            py="3rem"
          >
            OUR WORK
          </Heading>
          <Flex
            w="100%"
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems="center"
            overflowX={"clip"}
            display={["none", "none", "flex", "flex", "flex"]}
          >
            <PortCard
              subImage={giga_logo}
              image={giga_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  Cignal Play
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Take some of the best content from the country’s leading
                  satellite service provider, Cignal, with you wherever,
                  whenever. Cignal Play, awesome content everywhere.
                </Text>
              }
              additionalActions={
                <Flex flexDirection={"row"} flexWrap="wrap">
                  <a
                    target={"_blank"}
                    href="https://play.google.com/store/apps/details?id=tv.cignal.cignalplay&hl=en&gl=US"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  >
                    <Image objectFit={"contain"} src={gplay} />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://apps.apple.com/ph/app/cignal-play/id1293561677"
                  >
                    <Image objectFit={"contain"} src={appstore} />
                  </a>
                </Flex>
              }
            />
            <PortCard
              subImage={cup_sub}
              image={cup_icon}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  English Vocabulary
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  English Vocabulary in Use Elementary Activities provides 120
                  interactive activities designed to help boost your confidence
                  along with your vocabulary skills.
                  <br />
                  <br />
                  Whether you're studying on your own or in class, English
                  Vocabulary in Use Elementary includes all the words and
                  phrases you need at elementary level to understand and be
                  understood in English. This new app is adapted from the
                  English Vocabulary in Use Elementary CD-ROM to make activities
                  even more useful and accessible.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    {/* <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=tv.cignal.cignalplay&hl=en&gl=US"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a> */}
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/app/english-vocabulary-in-use/id582201474?ls=1"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a>
                  </Flex>
                </>
              }
            />
            <PortCard
              image={da_icon}
              subImage={doc_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  Doc Ayn
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Doc Ayn allows patients to book appointments with their OB
                  GYN, track their menses, monitor their pregnancy, and help
                  remind them of the supplements and medications they need to
                  take.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.docayn.app"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a>
                    {/* <a
                      target={"_blank"}
                      href="https://apps.apple.com/app/english-vocabulary-in-use/id582201474?ls=1"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a> */}
                  </Flex>
                </>
              }
            />
            <PortCard
              image={rc_logo}
              subImage={rc_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  My RCF
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  The RCF Mobile App allows you to easily access and manage your
                  personal or corporate account on the go. Customers with
                  personal loans can track their payment due dates, remaining
                  balance, credit limit and even apply for a loan right quickly.
                  <br />
                  <br />
                  Corporate clients and their employees can enjoy the
                  self-service such as time and attendance management, leave and
                  cash advance applications and payroll and payslip viewing.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    {/* <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.docayn.app"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a> */}
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/us/app/myrcf/id1531321927"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a>
                  </Flex>
                </>
              }
            />
            <PortCard
              image={ta_logo}
              subImage={ta_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  Transitions
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Engage with your colleagues through social feed and
                  communities Stay connected with the news feeds and live stream
                  event Listen to all Transitions podcast library anytime Get
                  access to your professional apps
                </Text>
              }
              additionalActions={<></>}
            />
            <PortCard
              image={whs_logo}
              subImage={whs_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  WHS Monitor Mobile
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  WHS Monitor Mobile provides access to a suite of features to
                  manage, record and report on all aspects of an organisation's
                  health and safety obligations including incident reporting,
                  risk management, audits and inspections, asset maintenance and
                  chemical management.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.au.whs.agtech&hl=en&gl=US"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/au/app/whs-monitor/id1065412557"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a>
                  </Flex>
                </>
              }
            />
            <PortCard
              subImage={atlas_icon}
              image={atlas_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  Atlas Fx
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Atlas Fertilizer Expert (Atlas Fx) is a user-friendly
                  decision-support tool that gives fertilizer recommendations
                  for rice and corn to help farmers achieve higher yield and
                  profit.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.atlasfertilizer.fx"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/ph/app/atlas-fx/id1519650704"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a>
                  </Flex>
                </>
              }
            />
            <PortCard
              subImage={orb_icon}
              image={orb_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"1.25rem"}
                  fontWeight="extrabold"
                >
                  Orbeas Rogue
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Orbeas Rogue allows users to learn interesting and unusual
                  facts about the world around them. Using Augmented Reality and
                  GPS technology, Orbeas Rogue will use your smart device to
                  highlight nearby places. Download now to discover how your
                  ordinary world has gone rogue!
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.holton.monocle"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/us/app/orbeas-rogue/id1593810512"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a>
                  </Flex>
                </>
              }
            />
          </Flex>
          <Flex
            w="100%"
            flexDirection={"row"}
            justifyContent={"space-evenly"}
            alignItems="center"
            overflowX={"clip"}
            display={["flex", "flex", "none", "none", "none"]}
            wrap={"wrap"}
          >
            <SmallerPortCard
              subImage={giga_logo}
              image={giga_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  Cignal Play
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Take some of the best content from the country’s leading
                  satellite service provider, Cignal, with you wherever,
                  whenever. Cignal Play, awesome content everywhere.
                </Text>
              }
              additionalActions={
                <Flex flexDirection={"row"} flexWrap="wrap">
                  <a
                    target={"_blank"}
                    href="https://play.google.com/store/apps/details?id=tv.cignal.cignalplay&hl=en&gl=US"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  >
                    <Image
                      style={style.downloadButton}
                      objectFit={"contain"}
                      src={gplay}
                    />
                  </a>
                  <a
                    target={"_blank"}
                    href="https://apps.apple.com/ph/app/cignal-play/id1293561677"
                  >
                    <Image
                      style={style.downloadButton}
                      objectFit={"contain"}
                      src={appstore}
                    />
                  </a>
                </Flex>
              }
            />
            <SmallerPortCard
              subImage={cup_sub}
              image={cup_icon}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  English Vocabulary
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  English Vocabulary in Use Elementary Activities provides 120
                  interactive activities designed to help boost your confidence
                  along with your vocabulary skills.
                  <br />
                  <br />
                  Whether you're studying on your own or in class, English
                  Vocabulary in Use Elementary includes all the words and
                  phrases you need at elementary level to understand and be
                  understood in English. This new app is adapted from the
                  English Vocabulary in Use Elementary CD-ROM to make activities
                  even more useful and accessible.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    {/* <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=tv.cignal.cignalplay&hl=en&gl=US"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a> */}
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/app/english-vocabulary-in-use/id582201474?ls=1"
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={appstore}
                      />
                    </a>
                  </Flex>
                </>
              }
            />
            <SmallerPortCard
              image={da_icon}
              subImage={doc_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  Doc Ayn
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Doc Ayn allows patients to book appointments with their OB
                  GYN, track their menstrual cycle, monitor their pregnancy, and
                  help remind them of the supplements and medications they need
                  to take.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.docayn.app"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={gplay}
                      />
                    </a>
                    {/* <a
                      target={"_blank"}
                      href="https://apps.apple.com/app/english-vocabulary-in-use/id582201474?ls=1"
                    >
                      <Image objectFit={"contain"} src={appstore} />
                    </a> */}
                  </Flex>
                </>
              }
            />
            <SmallerPortCard
              image={rc_logo}
              subImage={rc_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  My RCF
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  The RCF Mobile App allows you to easily access and manage your
                  personal or corporate account on the go. Customers with
                  personal loans can track their payment due dates, remaining
                  balance, credit limit and even apply for a loan right quickly.
                  <br />
                  <br />
                  Corporate clients and their employees can enjoy the
                  self-service such as time and attendance management, leave and
                  cash advance applications and payroll and payslip viewing.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    {/* <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.docayn.app"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image objectFit={"contain"} src={gplay} />
                    </a> */}
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/us/app/myrcf/id1531321927"
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={appstore}
                      />
                    </a>
                  </Flex>
                </>
              }
            />
            <SmallerPortCard
              image={ta_logo}
              subImage={ta_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  Transitions
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Engage with your colleagues through social feed and
                  communities Stay connected with the news feeds and live stream
                  event Listen to all Transitions podcast library anytime Get
                  access to your professional apps
                </Text>
              }
              additionalActions={<></>}
            />
            <SmallerPortCard
              image={whs_logo}
              subImage={whs_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  WHS Monitor Mobile
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  WHS Monitor Mobile provides access to a suite of features to
                  manage, record and report on all aspects of an organisation's
                  health and safety obligations including incident reporting,
                  risk management, audits and inspections, asset maintenance and
                  chemical management.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.au.whs.agtech&hl=en&gl=US"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={gplay}
                      />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/au/app/whs-monitor/id1065412557"
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={appstore}
                      />
                    </a>
                  </Flex>
                </>
              }
            />
            <SmallerPortCard
              subImage={atlas_icon}
              image={atlas_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  Atlas Fx
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Atlas Fertilizer Expert (Atlas Fx) is a user-friendly
                  decision-support tool that gives fertilizer recommendations
                  for rice and corn to help farmers achieve higher yield and
                  profit.
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.atlasfertilizer.fx"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={gplay}
                      />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/ph/app/atlas-fx/id1519650704"
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={appstore}
                      />
                    </a>
                  </Flex>
                </>
              }
            />
            <SmallerPortCard
              subImage={orb_icon}
              image={orb_sub}
              title={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#06667c"}
                  fontSize={"2rem"}
                  fontWeight="extrabold"
                >
                  Orbeas Rogue
                </Text>
              }
              content={
                <Text
                  fontFamily={"Open Sans"}
                  color={"#FFFFFF"}
                  fontSize={"1rem"}
                  my="2rem"
                >
                  Orbeas Rogue allows users to learn interesting and unusual
                  facts about the world around them. Using Augmented Reality and
                  GPS technology, Orbeas Rogue will use your smart device to
                  highlight nearby places. Download now to discover how your
                  ordinary world has gone rogue!
                </Text>
              }
              additionalActions={
                <>
                  <Flex flexDirection={"row"} flexWrap="wrap">
                    <a
                      target={"_blank"}
                      href="https://play.google.com/store/apps/details?id=com.holton.monocle"
                      style={{
                        marginRight: "0.5rem",
                      }}
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={gplay}
                      />
                    </a>
                    <a
                      target={"_blank"}
                      href="https://apps.apple.com/us/app/orbeas-rogue/id1593810512"
                    >
                      <Image
                        style={style.downloadButton}
                        objectFit={"contain"}
                        src={appstore}
                      />
                    </a>
                  </Flex>
                </>
              }
            />
          </Flex>
        </Flex>
      </Main>
      <Footer />

      <Modal
        isOpen={open}
        onClose={() => setOpen((prev) => !prev)}
        isCentered
        size={"5xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent py="2rem" mx="1rem">
          <ModalHeader>
            <Text
              style={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: "1.5rem",
              }}
            >
              Testimonials
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py="2rem`">{activeContent}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const style = {
  listStyle: {
    fontFamily: "Open Sans",
    fontSize: "1rem",
    color: "#32aab5",
    fontWeight: "500",
  },
  modalTextStyle: {
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: "1rem",
  },
  modalNameStyle: {
    textAlign: "right",
    color: "#06667c",
    fontSize: "1rem",
    fontFamily: "Open Sans",
  },
  downloadButton: {
    width: "150px",
    margin: "0.5rem",
  },
};
