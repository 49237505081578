import React, { ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

type ClearCardProps = {
  title?: string;
  content: ReactNode;
  icon: React.ReactNode;
};

export default function ClearCard({ content, icon, title }: ClearCardProps) {
  return (
    <Flex
      w="390px"
      borderRadius={20}
      h="530px"
      boxShadow={
        "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
      }
      p="2rem"
      my="1rem"
      flexDirection={"column"}
      alignItems="center"
      justifyContent={"space-evenly"}
      backgroundColor="#fff"
    >
      <Flex justifyContent={"center"} alignItems="center" h="100px">
        {icon}
      </Flex>
      <Box>{content}</Box>
    </Flex>
  );
}

const style = {
  text: {
    fontFamily: "Open Sans",
    color: "#4f4f4f",
  },
  heading: {
    fontFamily: "Montserrat",
    color: "#919E9C",
  },
};
